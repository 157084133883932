.vue-flow__minimap {
  background-color: #fff;
}

.vue-flow__minimap.pannable {
  cursor: grab;
}

.vue-flow__minimap.dragging {
  cursor: grabbing;
}

.vue-flow__minimap-mask.pannable {
  cursor: grab;
}
